.dashboard {
    width: 100%;
    .no-data-mobile {
        @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
            display: none;
        }
    }
    .no-data-desktop {
        display: none;
        @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            align-items: center;
            .no-data-illustration {
                margin-bottom: 20px;
            }
            .header {
                margin-bottom: 20px;
                .header-text {
                    margin-bottom: 5px;
                }
            }
            .action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .action-text {
                    width: 400px;
                }
            }
        }
    }
}

.onboarding {
    background-color: $white;
    padding: 30px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    height: 445px;
    .company-name{
        text-transform: capitalize;
    }
    .company-logo {
        max-width: 85px;
        max-height: 85px;
        margin-bottom: 20px;
    }
    .quickstart {
        margin: 20px 0 30px 0;
    }
    .steps-wrapper {
        overflow: hidden;
        .step {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            img {
                margin-right: 15px;
                padding: 10px;
                background: $lightest-green;
                width: 35px;
                height: 35px;
                border-radius: 5px;
            }
        }
    }
}
