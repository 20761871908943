.white-box {
    display: flex;
    justify-content: center;
    background-color: $white;
    padding: 10px;
    border-radius: 12px;
    width: 100%;
    .circularProgress {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
#boxStyle {
    margin-top: 15px;
    background-color: $white;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 15px;
    width: 50%;
}
#boxStyleComp {
    background-color: $white;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
}

#boxStyleMot {
    background-color: $white;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
}

#boxStyleAssessment {
    background-color: $white;
    padding: 20px;
    border-radius: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
    width: 50%;;
}

#boxStyleReports {
    background-color: $white;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 5px;
    .title{
        margin-botton : 20px;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; /* Align icons to the left */
        align-items: center;
        padding: 10px;
        margin-left:10px;
    }

    .image-container {
        display: flex;
        flex-direction: column; /* Stack the icon and heading vertically */
        text-align: center; /* Center the heading text */
        margin-right: 70px; /* Add a max gap of 70px between icons */
    }

    .image-container:last-child {
        margin-right: 0; /* Remove margin for the last image-container */
    }

    a {
        text-decoration: none;
        display: inline-block;
        padding: 10px;
        background-image: url('../assets/images/icons/downloadable-report.svg');
        height: 40px;
        width: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    a:hover {
        background-color: $light-green;
        color: white;
    }

    h2 {
        font-family: Raleway;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        color : $dark-grey;
    }


}

.progressDiv {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex-direction: row;
    background: linear-gradient(0deg, #F0F7F6, #F0F7F6), #FFFFFF;
    border-radius: 8px;
    margin-top: 10px;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    background-color: #f0f4f4;
    .progress {
        height: 8.5px;
    }
    .progress-bar{
        height: 5px;
        background: linear-gradient(269.99deg, #00B0A0 0.72%, #59287A 102.48%);
        border-radius: 5px;
    }
}

.talent-details-strengths-charts {
    padding: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    background-color: $white;
    border-radius: 12px;
    align-items: baseline;
    margin-top: 15px;
    .chartDiv {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .justify-content-div {
        justify-content: center;
        display: flex;
    }
}

.title{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    padding: 10px;
    font-size: 16px;
    line-height: 32px;
    color: $black;
}
.accordion-header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
}
.scoreline-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.grey-bar {
    font-size: 10px;
    color: grey;
}
.flex-div {
    display: flex;
    flex-direction: row;
}

.no-arrow::after {
    display: none !important;
}
